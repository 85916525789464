import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { Link, useNavigate } from "react-router-dom";
import { FaLinkedin } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import axiosInstance from "apiServices/axiosInstance";

export default function Footer() {
  const navigate = useNavigate();
  const [linksApiFlag, setLinksApiFlag] = useState(false);
  const [links, setLinks] = useState("");

  const fetchFooterLinksData = async () => {
    try {
      const response = await axiosInstance.get(
        `/user/configuration/one/social_media`,
        {}
      );
      // console.log("🚀 ~ fetchFooterLinksData ~ response:", response)
      if (response.status) {
        setLinks(response.data.social_media[0]);
      } else {
        // openNotification("danger", response.message);
      }
    } catch (error) {
      // console.log("fetchDaycareFormOptionData error:", error);
      // openNotification("danger", error.message);
    }
  };
  useEffect(() => {
    if (linksApiFlag) {
      setLinksApiFlag(false);
      fetchFooterLinksData();
    }
  }, [linksApiFlag]);
  useEffect(() => {
    setLinksApiFlag(true);
  }, []);

  return (
    <footer
      className={classNames(`footer  bg-[#504CBC]  flex flex-auto items-end `)}
    >
      <div className=" w-full container py-8">
        <div className="flex flex-col  justify-start sm:flex-row  sm:justify-between mx-auto">
          <div className="flex flex-col justify-center items-center   sm:flex-row sm:gap-4 lg:gap-6">
            <div className="mb-4">
              <ul className="font-poppins leading-5 sm:text-left">
                <li className="font-bold text-white text-[21px]">Links</li>
              </ul>
              <ul className="font-poppins pt-[10px] leading-5">
                <li className="text-white text-sm">
                  <Link to="/contact-us">Contact</Link>
                </li>
                <li className="text-white text-sm">
                  <Link to="/about-us">About</Link>
                </li>
                <li className="text-white text-sm">
                  <Link to="/faq">FAQ</Link>
                </li>
                <li className="text-white text-sm">
                  <Link to="/blogs">Blogs</Link>
                </li>
              </ul>
            </div>
            <div>
              <ul className="font-poppins leading-5 text-center md:text-left">
                <li className="font-bold text-white text-[21px]">Legal</li>
              </ul>
              <ul className="font-poppins leading-5 pt-[10px] text-center md:text-left ">
                <li className="text-white text-sm">
                  <Link className="underline" to="/terms-of-service">
                    Terms of Service
                  </Link>
                </li>
                <li className="text-white text-sm">
                  <Link className="underline" to="/privacy-policy">
                    Privacy Policy
                  </Link>
                </li>
                <li className="text-white text-sm">
                  <button
                    className="underline"
                    onClick={() => {
                      navigate("/agreement/daycare-agreement");
                    }}
                  >
                    Daycare License Agreement
                  </button>
                </li>
                <li className="text-white text-sm">
                  <button
                    className="underline"
                    onClick={() => {
                      navigate("/agreement/parent-agreement");
                    }}
                  >
                    Parent License Agreement
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div className=" flex justify-end items-end">
            <div className="w-36 sm:mx-0 mx-auto  ">
              <p className="text-white text-center font-bold text-lg font-poppins mt-4 md:mt-2">
                We’re social!
              </p>
              <div className="flex justify-center gap-4  mt-2">
                <Link to={links.instagram} target="_blank">
                  <FaInstagram size={50} color="#ffffff" />
                </Link>
                <Link to={links.linkedIn} target="_blank">
                  <FaLinkedin size={50} color="#ffffff" />
                </Link>
                {/* <img className="mr-2" src={Facebook} alt="" /> */}
                {/* <img src={Twitter} alt="" /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
