export const ROOT = "/";
export const DAYCARE_PREFIX_PATH = "/daycare";
export const PARENT_PREFIX_PATH = "/parent";
export const AUTH_PREFIX_PATH = "/auth";
export const PAGES_PREFIX_PATH = "/pages";

export const unAuthenticatedPages = [
  "/",
  "/browse-daycares",
  "/browse-daycares/daycare-details/:id",
  "/terms-of-service",
  "/privacy-policy",
  "/agreement/:type",
  "/about-us",
  "/contact-us",
  "/faq",
  "/blogs",
  "/blog-details/:slug",
];
