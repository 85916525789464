import React, { useState, useEffect } from "react";
import Layout from "./components/layout";
import useAuth from "../src/utils/hooks/useAuth";
import Header from "components/template/header";
import Footer from "components/template/footer";
import { HelmetProvider } from "react-helmet-async";

import { useSelector } from "react-redux";
import { isUnauthenticatedPage } from "utils/functions/isUnauthenticatedPage";

function App() {
  // console.log("🚀 ~ App ~ location:", window.location.pathname)
  const { authenticated } = useAuth();
  // console.log("🚀 ~ App ~ authenticated:", authenticated)
  const { userData } = useSelector((state) => state?.auth?.user);
  // console.log("🚀 ~ App ~ userData:", userData)
  const [headerVisible, setHeaderVisible] = useState(false);
  const [footerVisible, setFooterVisible] = useState(false);

  // const headlessPages = ["/select-account", "/agreement/:type", "/profile-notification", "/daycare-profile", "/parent-profile"]

  // Use useEffect to update visibility based on location and authentication
  // useEffect(() => {
  //   if (
  //     isUnauthenticatedPage(window.location.pathname) ||
  //     window.location.pathname === "/" ||
  //     authenticated
  //   ) {
  //     if (authenticated && userData && userData?.authority[0] === "user") {
  //       if (headlessPages.includes(window.location.pathname)) {
  //         setHeaderVisible(false);
  //         setFooterVisible(false);
  //       } else {
  //         setHeaderVisible(true);
  //         setFooterVisible(true);
  //       }
  //     } else if (authenticated && userData && userData?.authority[0] === "daycare") {
  //       if (headlessPages.includes(window.location.pathname)) {
  //         setHeaderVisible(false);
  //         setFooterVisible(false);
  //       } else {
  //         setHeaderVisible(true);
  //         setFooterVisible(true);
  //       }

  //     } else if (authenticated && userData && userData?.authority[0] === "parent") {
  //       if (headlessPages.includes(window.location.pathname)) {
  //         setHeaderVisible(false);
  //         setFooterVisible(false);
  //       }
  //       else if (window.location.pathname == "/payment-success" || window.location.pathname == "/payment-failed" || window.location.pathname == "/registration-fee-payment-success" || window.location.pathname == "/registration-fee-payment-failed") {
  //         setHeaderVisible(true);
  //         setFooterVisible(false);
  //       }
  //       else {
  //         setHeaderVisible(true);
  //         setFooterVisible(true);
  //       }
  //     } else {
  //       setHeaderVisible(true);
  //       setFooterVisible(true);
  //     }
  //   } else if (window.location.pathname == `/auth/sign-in`) {
  //     setHeaderVisible(true);
  //     setFooterVisible(true);
  //   }
  //   else {
  //     setHeaderVisible(false);
  //     setFooterVisible(false);
  //   }
  // }, [window.location.pathname, authenticated]);
  // console.log("🚀 ~ App ~ filter :|| ", protectedRoutes?.filter((route)=>route.path===window.location.pathname).length<1)

  const headlessPages = [
    "/select-account",
    "/agreement/:type",
    "/profile-notification",
    "/daycare-profile",
    "/parent-profile",
  ];

  // Create a function to handle pattern matching for headless pages
  const isHeadlessPage = (path) => {
    return headlessPages.some((page) => {
      if (page === path) {
        return true;
      }
      const pattern = new RegExp(
        "^" + page.replace(/:[^\s/]+/g, "([^\\/]+)") + "$"
      );
      return pattern.test(path);
    });
  };

  useEffect(() => {
    const pathname = window.location.pathname;

    // Helper function to handle header and footer visibility
    const handleVisibility = (showHeader, showFooter) => {
      setHeaderVisible(showHeader);
      setFooterVisible(showFooter);
    };

    if (isUnauthenticatedPage(pathname) || pathname === "/" || authenticated) {
      // Default or authenticated user flow
      if (authenticated && userData) {
        const authority = userData?.authority[0];

        if (
          authority === "user" ||
          authority === "daycare" ||
          authority === "parent"
        ) {
          // Common logic for `user`, `daycare`, and `parent`
          if (isHeadlessPage(pathname)) {
            handleVisibility(false, false); // Hide both header and footer
          } else if (
            authority === "parent" &&
            [
              "/payment-success",
              "/payment-failed",
              "/registration-fee-payment-success",
              "/registration-fee-payment-failed",
            ].includes(pathname)
          ) {
            handleVisibility(true, false); // Show header but hide footer for payment-related pages
          } else {
            handleVisibility(true, true); // Show both header and footer
          }
        } else {
          handleVisibility(true, true); // Fallback: Show both for unknown cases
        }
      } else {
        handleVisibility(true, true); // Not authenticated or no user data
      }
    } else if (pathname === `/auth/sign-in`) {
      handleVisibility(true, true); // Always show header and footer on sign-in page
    } else {
      handleVisibility(false, false); // For other authenticated pages, hide both
    }
  }, [window.location.pathname, authenticated]);

  return (
    <>
      <HelmetProvider>
        <div
          className={`top-0 ${
            window.location.pathname == "/payment-success" ||
            window.location.pathname == "/payment-failed" ||
            window.location.pathname == "/registration-fee-payment-success" ||
            window.location.pathname == "/registration-fee-payment-failed"
              ? "fixed"
              : "sticky"
          } w-full z-10 shadow-lg`}
        >
          {headerVisible && <Header />}{" "}
        </div>
        <Layout />
        {footerVisible && <Footer />}
      </HelmetProvider>
    </>
  );
}

export default App;
